<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`purchase_part_header purchase_part-header_number ${cardCssClass} pr-2 py-2 position-relative`" body-class="purchase_part-body p-0" border-variant="light">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">44-ФЗ</b-badge>
                        <router-link class="white-system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id } }" exact target="_blank">{{ contract.reg_number }}</router-link>
                        <template v-if="contract.purchase_object"> На закупку "{{ contract.purchase_object }}"</template>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase" md="auto">
                        {{ contract.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <div class="purchase_part-subheader">
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-14 green-color">
                        <abbr title="Начальная цена контракта">НЦК</abbr>: <span class="fw-600">{{ $formatPrice(contract.start_price) }} руб.</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-14 green-color">
                        Предложений: <span class="fw-600">{{ contract.purchase_actual_offers_count }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-14 green-color"
                        >Лучшее: <span class="fw-600"> {{ contract.purchase_min_offer_cost ? $formatPrice(contract.purchase_min_offer_cost) + ' руб.' : '-' }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-14 green-color">
                        Снижение: <span class="fw-600">{{ contract.economy_percentage }} %</span>
                    </span>
                </b-col>
            </div>
            <b-row class="purchase_part-body py-0">
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-2" v-if="!!contract.planned_end_date">
                            <p class="fs-14 m-0 grey-color">Планируемая дата исполнения:</p>
                            <p class="fw-600 fs-14 m-0 grey-color">
                                {{ getDateFormat(contract.planned_end_date) }}
                            </p>
                        </div>
                        <div class="my-2" v-if="contract.status.id === 'contract:active' || contract.status.id === 'contract:fulfilled' || contract.status.id === 'contract:unfulfilled' || contract.status.id === 'contract:terminated'">
                            <p class="fs-14 m-0 grey-color">Договор заключен:</p>
                            <p class="fs-14 m-0 grey-color fw-600 time_to_end">
                                {{ getDateFormat(contract.contract_date) }}
                            </p>
                        </div>
                    </div>
                    <div class="my-2">
                        <p class="fs-14 m-0 grey-color" v-if="contract.contract_price">
                            Цена договора:
                            <span class="fw-600">{{ $formatPrice(contract.contract_price) }} руб.</span>
                        </p>
                    </div>
                    <div v-if="isCreator" class="my-2">
                        <purchase-comment-edit-block :purchase="contract" @refresh="refresh" />
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-2">
                            <p class="fs-14 grey-color m-0">Предмет закупки:</p>
                            <p class="fs-14 grey-color fw-600">
                                {{ contract.purchase_name }}
                            </p>
                        </div>
                        <div class="my-2 d-flex flex-column">
                            <p class="fs-14 grey-color m-0">Заказчик:</p>
                            <router-link class="system_link-style fw-600 text-uppercase fs-14" :to="'/organizations/' + contract.organization_id" target="_blank">
                                {{ contract.customer_short_name }}
                            </router-link>
                        </div>
                        <div class="my-2 d-flex flex-column">
                            <p class="fs-14 grey-color m-0">Поставщик:</p>
                            <router-link v-if="contract.organization_final_id" class="system_link-style fw-600 text-uppercase fs-14" :to="'/organizations/' + contract.organization_final_id" target="_blank">
                                {{ contract.organization_final_name }}
                            </router-link>
                        </div>
                        <div class="my-2">
                            <p class="fs-14 m-0 grey-color">
                                Количество позиций:
                                <span class="fw-600">{{ contract.items_count }}</span>
                            </p>
                        </div>
                        <div v-if="contract.delivery_addresses && contract.delivery_addresses.length" class="my-2">
                            <p class="fs-14 m-0 grey-color">Место доставки или указание на самовывоз:</p>
                            <p class="fs-14 m-0 grey-color fw-600" v-for="(address, index) in contract.delivery_addresses" :key="index" v-bind="address">
                                {{ address.address }}
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col atmo-column atmo-special-style">
                    <div>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-2">
                            <i class="atmo-icon-search link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id } }" exact target="_blank">Информация о закупке</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-2">
                            <i class="atmo-icon-dynamic link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-proposals' }" exact target="_blank">Динамика предложений</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-2">
                            <i class="atmo-icon-shield link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-supplier-info' }" exact target="_blank">Итоговый протокол</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-2">
                            <i class="atmo-icon-contract link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-contract' }" exact target="_blank">Договор</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-2">
                            <i class="atmo-icon-fulfilled link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: contract.id }, hash: '#atmo-contract-fulfillment' }" exact target="_blank">Исполнение договора</router-link>
                        </p>
                        <div v-if="isCreator" class="my-2">
                            <b-btn v-if="contract.rating_allowed" class="btn btn-card-action btn-card-action my-1" @click="ratingModalVisible = true">Оценить поставщика </b-btn>
                            <router-link v-if="contract.allow_external_purchase" :to="{ name: 'ExternalPurchaseCreate', params: { mode: 'create', id: purchase.id } }" class="btn btn-card-action btn-card-action-light my-1" tag="button">
                                Закупить вне ИС
                            </router-link>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-modal v-if="ratingModalVisible" id="ratingModal" v-model="ratingModalVisible" centered hide-footer title="Оцените поставщика">
            <b-row class="feedback_modal">
                <b-col>
                    <b-form class="px-4 pb-4" @submit.prevent="onSubmitRating">
                        <b-row class="py-2" no-gutters>
                            <b-col class="fs-14 grey-color">
                                <h5>{{ contract.organization_final_name }}</h5>
                            </b-col>
                        </b-row>
                        <b-row class="py-2" no-gutters>
                            <b-col class="fs-14 grey-color">
                                <b-form-input v-model="ratingValue" v-stop-number-mousewheel max="5" min="1" required type="number"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-5">
                            <b-col>
                                <b-overlay :show="ratingSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                                    <b-button class="mr-2 text-uppercase" type="reset" variant="custom-outline-secondary" @click="cancelRatingModal">Отмена</b-button>
                                </b-overlay>
                                <b-overlay :show="ratingSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                                    <b-button class="text-uppercase" type="submit" variant="custom-green">Отправить</b-button>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
import PurchaseCommentEditBlock from "@/components/common/purchase-comment-edit-block.vue";
import Purchases from "@/services/api/Purchases";

export default {
    name: 'customer-fl44-contract-card',
    components: {PurchaseCommentEditBlock},
    props: {
        contract: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ratingModalVisible: false,
            ratingValue: 5,
            ratingSending: false
        }
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        cancelRatingModal() {
            this.ratingValue = 5;
            this.ratingModalVisible = false;
            this.ratingSending = false;
        },
        onSubmitRating() {
            this.ratingSending = true;
            Purchases.setRating(this.contract.id, parseInt(this.ratingValue)).then(() => {
                this.cancelRatingModal();
                this.refresh();
            });
        },
    },
    computed: {
        cardCssClass() {
            switch (this.contract.status.id) {
                case 'contract:unfulfilled':
                case 'contract:terminated':
                case 'contract:failed':
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        },
        cardIcon() {
            switch (this.contract.status.id) {
                case 'contract:active':
                    return 'file-earmark-ruled';
                case 'contract:fulfilled':
                    return 'file-earmark-check';
                case 'contract:unfulfilled':
                case 'contract:failed':
                case 'contract:terminated':
                    return 'x-circle';
                default:
                    return '';
            }
        },
        isCreator() {
            return this.$store.state.user.organization_id === this.contract.organization_id;
        }
    }
};
</script>

<style scoped>
.link-icon {
    padding-right: 5px;
    color: #d5d5d5;
    font-size: medium;
}
.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.purchase_part {
    background: #fff;
}
.card-header-grey {
    background-color: #959ba4 !important;
}
.card-header-green {
    background-color: var(--green-color);
}
.card-header-blue {
    background-color: #40acb4 !important;
}
.card-header-yellow {
    background-color: #f6b33a !important;
}
.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 4px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
