var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `purchase_part_header purchase_part-header_number ${_vm.cardCssClass} pr-2 py-2 position-relative`,
            "body-class": "purchase_part-body p-0",
            "border-variant": "light",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v("44-ФЗ")]
                          ),
                          _c("b-badge", { staticClass: "my-auto fs-14 mr-2" }, [
                            _vm._v("Вне ИС"),
                          ]),
                          _vm.contract.status.id !== "draft"
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "white-system_link-style",
                                  attrs: {
                                    to: {
                                      name: "ExternalPurchaseShow",
                                      params: { id: _vm.contract.id },
                                    },
                                    exact: "",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.contract.reg_number))]
                              )
                            : [_vm._v(_vm._s(_vm.contract.reg_number))],
                          _vm.contract.purchase_object
                            ? [
                                _vm._v(
                                  ' На закупку "' +
                                    _vm._s(_vm.contract.purchase_object) +
                                    '"'
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-nowrap text-uppercase",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.contract.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-0" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
                [
                  _c("div", [
                    _vm.contract.status.id === "contract:active" ||
                    _vm.contract.status.id === "contract:fulfilled" ||
                    _vm.contract.status.id === "contract:unfulfilled" ||
                    _vm.contract.status.id === "contract:terminated"
                      ? _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("Договор заключен:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-12 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract.contract_conclusion_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.contract.status.id === "contract:fulfilled" &&
                    _vm.contract.contract_execution_date
                      ? _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("Договор исполнен:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-12 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract.contract_execution_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.isCreator
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c("purchase-comment-edit-block", {
                            attrs: {
                              purchase: _vm.contract,
                              type: "fl44_external",
                            },
                            on: { refresh: _vm.refresh },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCreator && _vm.contract.status.id !== "draft"
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "btn btn-card-action my-3",
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseEdit",
                                  params: { id: _vm.contract.id },
                                },
                              },
                            },
                            [_vm._v("Редактировать")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
                [
                  _c("div", [
                    _c("div", { staticClass: "my-2" }, [
                      _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                        _vm._v("Предмет закупки:"),
                      ]),
                      _c("p", { staticClass: "fs-12 grey-color fw-600" }, [
                        _vm._v(" " + _vm._s(_vm.contract.purchase_name) + " "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "my-2 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                          _vm._v("Заказчик:"),
                        ]),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "system_link-style fw-600 text-uppercase fs-12",
                            attrs: {
                              to:
                                "/organizations/" +
                                _vm.contract.organization_id,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.contract.customer_short_name) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "my-2 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                          _vm._v("Поставщик:"),
                        ]),
                        !_vm.contract.is_private_provider
                          ? [
                              _vm.contract.supplier_id
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "system_link-style fw-600 text-uppercase fs-12",
                                      attrs: {
                                        to:
                                          "/organizations/" +
                                          _vm.contract.supplier_id,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.contract.supplier_short_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _c("p", { staticClass: "fs-12 fw-600 m-0" }, [
                                _vm._v("Физическое лицо"),
                              ]),
                            ],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "my-2" }, [
                      _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                        _vm._v(" Количество позиций: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.contract.deliverables_count)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "my-2" }, [
                      _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                        _vm._v(" Сумма закупки: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(
                            _vm._s(_vm.$formatPrice(_vm.contract.price)) +
                              " руб."
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "purchase_part-body_col atmo-column atmo-special-style",
                  attrs: { md: "4" },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isCreator && _vm.contract.status.id === "draft"
                        ? [
                            _c(
                              "b-btn",
                              {
                                staticClass:
                                  "btn btn-card-action btn-card-action-green my-1",
                                attrs: {
                                  to: {
                                    name: "ExternalPurchaseCreate",
                                    params: {
                                      mode: "edit_draft",
                                      id: _vm.contract.id,
                                    },
                                  },
                                },
                              },
                              [_vm._v("Редактировать")]
                            ),
                            _c(
                              "b-btn",
                              {
                                staticClass:
                                  "btn btn-card-action btn-card-action-light my-1",
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = true
                                  },
                                },
                              },
                              [_vm._v("Удалить")]
                            ),
                          ]
                        : [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "d-flex align-items-center green-color fs-12 fw-600 my-2",
                              },
                              [
                                _c("i", {
                                  staticClass: "atmo-icon-search link-icon",
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "system_link-style",
                                    attrs: {
                                      to: {
                                        name: "ExternalPurchaseShow",
                                        params: { id: _vm.contract.id },
                                      },
                                      exact: "",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Информация о закупке")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "d-flex align-items-center green-color fs-12 fw-600 my-2",
                              },
                              [
                                _c("i", {
                                  staticClass: "atmo-icon-shield link-icon",
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "system_link-style",
                                    attrs: {
                                      to: {
                                        name: "ExternalPurchaseShow",
                                        params: { id: _vm.contract.id },
                                        hash: "#supplier-info",
                                      },
                                      exact: "",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Поставщик")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "d-flex align-items-center green-color fs-12 fw-600 my-2",
                              },
                              [
                                _c("i", {
                                  staticClass: "atmo-icon-contract link-icon",
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "system_link-style",
                                    attrs: {
                                      to: {
                                        name: "ExternalPurchaseShow",
                                        params: { id: _vm.contract.id },
                                        hash: "#contract",
                                      },
                                      exact: "",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Договор")]
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "delete-modal",
          attrs: {
            title: "Удалить закупку",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            centered: "",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите удалить закупку " +
                _vm._s(_vm.contract.reg_number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _vm._v("  "),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-danger" },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(_vm.contract.id)
                        },
                      },
                    },
                    [_vm._v("Удалить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }